import React from "react";
import Link from "gatsby-link";
import Layout from "../layouts/en";
import "./index.css";
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import 'react-animated-slider/build/horizontal.css';
import novapolhora from "../static/images/polhora-invest-grey.png"
import factory from '../static/images/factory.svg'
import truck from '../static/images/truck.svg'
import MapWithAnOverlayView from "../components/OverlayMapEn";
import "../components/OverlayMap.css";
import SEO from "../components/seo.js";
import { graphql } from "gatsby"
import downloadFile from '../static/images/np-plan4.pdf' 


class Parcels extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: "S2B", 
      hideNav : false, 
      onSelect : "nic" , 
      opened : false, 
      setModal : false, 
      boxOne: false,
      boxTwo: false,
      boxThree: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.toggleBox = this.toggleBox.bind(this);
    this.boxOne = this.boxOne.bind(this);
    this.boxTwo = this.boxTwo.bind(this);
    this.boxThree = this.boxThree.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }


	toggleBox() {
		const { opened } = this.state;
		this.setState({
			opened: !opened,
		});
  }
  
  boxOne() {
		const { boxOne } = this.state;
		this.setState({
			boxOne: !boxOne,
    });
  }

  boxTwo() {
		const { boxTwo } = this.state;
		this.setState({
			boxTwo: !boxTwo,
		});
  }

  boxThree() {
		const { boxThree } = this.state;
		this.setState({
			boxThree: !boxThree,
		});
	}


  render() {

    return (
      <div>
            <Layout location={this.props.location}>
    <div>
      <Container fluid className="featured-photo">
        <img src={novapolhora} alt="nova polhora logo" className="" width="100%"/>
      </Container>
      <Container fluid className="title-npi pt-5 pb-5">
        <h3 className="text-uppercase">MODERN Logitics and Industrial park</h3>
        <h1><span  className="title-title text-uppercase">LPP NOVÁ POLHORA</span></h1>
        <h4>Offers storage facilities for rent but also selling of land for private construction.<br/>
        The Logistics and Industrial Park is situated in the north-south European corridor (Poland- Greece)</h4>
      </Container>
      <Container fluid>
        <Row>
          <Col md={8} className="features-beg pt-5 pb-5">
            <Row className="">
              <Col md={6}>
                <div className="features-beg-title container-basic">
                    <div className="text-center  mb-3">
                        <img src={ truck } className="" alt="truck" height="130"/>
                    </div>
                    <h5 className="text-uppercase">Great location:</h5>
                    <ul>
                        <li>on D1 motorway exit Nová Polhor</li>
                        <li>30 minutes from Košice Airport </li>
                        <li>20 minutes from city centre of Košice and Prešov</li>
                        <li>with more than 500 000 inhabitants</li>
                        <li>also accessible by public transport </li>
                    </ul>
                </div>
              </Col>
              <Col md={6}>
                <div className="features-beg-title container-basic">
                    <div className="text-center mb-3">
                        <img src={ factory } className="" alt="factory" height="130"/>
                    </div>
                    <h5 className="text-uppercase">Great potential</h5>
                    <ul>
                        <li>possibility build 166.000 m<sup>2</sup> of storage facilities</li>
                        <li>selling of land for construction to the end user</li>
                    </ul>
                </div>
              </Col>
            </Row>
            <div className="button-div">
            <Link to="/en/location/"><Button outline className="button-basic primary button-margin" >Check accessibility</Button></Link>
            <Link to="/en/contact/">  <Button outline className="button-basic secondary button-margin" >Contact us</Button></Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className=" special-offer">
      <a href="https://www.sbinvest.sk/"> New project LPP Šarišské Bohdanovce</a>
      </Container>
      <Container fluid className="features-container">
        <h2 className="features-title">About the project</h2>
        <div>
          <MapWithAnOverlayView
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh1wAZLx_f2riCJadaLxEpjUqkGX_Gy8&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            onSelect={(parcel) => this.setState({onSelect: parcel})}
            location={this.props.location}
          />
        </div>
        <div className="button-div">
        <Link to="/en/location/"> <Button outline className="button-basic primary-invert button-margin" >See other benefits</Button></Link>
        <a href={downloadFile} download>{` `} <Button outline className="button-basic secondary button-margin" >Download plan</Button></a>
        </div>
      </Container>
      <Container fluid className="container-utilization pt-5 pb-5">
        <Row>
          <Col md={4} className="col-utilization">
            <div className="content-utilization">
              <h3>About the concept</h3>
              <p className={this.state.boxOne ? '' : 'content-utilization-paragraph text-justify'} >
              Logistics and Industrial Park is between the municipality and the motorway, it consists 
              of building complexes suitable for diverse areas of logistics, safe manufacturing, but 
              also trade and services. All building complexes are planned along the collector road 
              that goes through the whole Park. The Park has a great connectivity through a roundabout 
              to the motorway feeder from one side and to the road 3325 from the other side.


              </p>
              <Button outline className="button-basic primary" onClick={this.boxOne} >Show{this.state.boxOne ? ' less' : ' more'} </Button>
            </div>
          </Col>
          <Col md={4} className="col-utilization">
            <div className="content-utilization">
              <h3>Infrastructure</h3>
              <p className={this.state.boxTwo ? '' : 'content-utilization-paragraph'}>
              The construction of the STL gas pipeline has been completed, with approval by 12/2024. A feeder road has been built and approved, which runs through the centre of the LPP and allows easy access to the individual sites. 
                The park is connected to approved utilities including electricity, potable water and sewer. The newly constructed WWTP will be approved by 12/2024.                
              </p>
              <Button outline className="button-basic primary" onClick={this.boxTwo} >Show{this.state.boxTwo ? ' less' : ' more'} </Button>
            </div>
          </Col>
          <Col md={4} className="col-utilization">
            <div className="content-utilization">
              <h3>Zoning Plan</h3>
              <p className={this.state.boxThree ? '' : 'content-utilization-paragraph'}>
              Nová Polhora's zoning plan was approved upon the resolution of municipal 
              Council of Nová Polhora No. 48/2010 on 09.09.2010. The binding part 
              is in the regulation no. 1/2010. The first construction phase of S1 is finished; 
              beginning of the second phase starts in spring 2020. The zoning decision 
              documentation of the construction S1, comprise basic transport and 
              technical frame to ensure the functionality of the subsequent 
              structures S2 to S7, which are the subject of individual zoning procedures. 
              All sectors were issued zoning decision documentation. In august 2020, 
              there will be a change in the zoning decision document of sector S6 
              (change in the division of the sector into 7 separate lands 
              instead of 2 lands)
              </p>
              <Button outline className="button-basic primary" onClick={this.boxThree} >Show{this.state.boxThree ? ' less' : ' more'} </Button>
             
            </div>
          </Col>
        </Row>
      </Container> 
    </div>
    <SEO title="O projekte" />
  </Layout>
      </div>
    );
  }
}
export default Parcels;


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        languages {
        defaultLangKey
        langs
        }
      }
    }
  }
`
